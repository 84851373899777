import {
 Container,
 Sidebar as RSuiteSidebar, // Renamed here
 Sidenav,
 Nav,
 IconButton,
 Stack,
 HStack,
} from "rsuite";
import React, { useState, useEffect } from "react";
import { Icon } from "@rsuite/icons";
import {
 MdPersonAdd,
 MdHome,
 MdLogout,
 MdGroup,
 MdSettings,
 MdKeyboardArrowLeft,
 MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import logoImage from "../Assets/Image/LogoFullColour.png";
import { Image, NavLink, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout, updateUserLastSeen } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import iconAttendanceMachine from "../Assets/icon/attendance_machine.png";
import iconUsers from "../Assets/icon/users.png";
import { updateSettings } from "../utils/localStorage";

const SideNavigationBar = () => {
 const dispatch = useDispatch();
 const logoutHandler = (e) => {
  if (e) {
   e.preventDefault(); // Mencegah halaman refresh
  }

  dispatch(logout());
 };
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const settingsDetails = useSelector((state) => state.settingsDetails);
 const { settings } = settingsDetails;

 const [expand, setExpand] = useState(settings?.sideNav?.expand ?? true);

 useEffect(() => {
  if (expand !== undefined) {
   updateSettings(`sideNav`, `expand`, expand);
  }
  //console.log({ settings });

  if (userInfo && !userLoading) {
   // Memulai interval
   const intervalId = setInterval(() => {
    dispatch(updateUserLastSeen(userInfo));
   }, 5000); // 5000ms = 5 detik

   // Membersihkan interval ketika komponen di-unmount
   return () => {
    clearInterval(intervalId);
   };
  }

  return () => {}; // Mengembalikan fungsi kosong ketika userInfo tidak ada
  // eslint-disable-next-line
 }, [userInfo, expand]);

 if (!userInfo) {
  return <></>;
 }

 const expandToggleHandler = () => {
  setExpand(!expand);
 };
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 return (
  <div
   className="sidebar-page"
   style={{
    position: "relative",
    width: expand ? 200 : 56,
    height: "100vh",
    zIndex: 999,
   }}
  >
   <Container>
    <RSuiteSidebar
     style={{
      display: "flex",
      flexDirection: "column",
      top: 0,
      height: "100%",
      position: "fixed",
      borderRight: "1px solid black", // Tambahkan garis di pinggir kanan
      backgroundColor: "#ffffff",
      boxShadow: "0 4px 2px -2px gray" /* Tambahkan bayangan untuk estetika */,
     }}
     width={expand ? 225 : 56}
     collapsible
    >
     <Sidenav.Header>
      <Logo expand={expand} />
     </Sidenav.Header>
     <Sidenav expanded={expand} defaultOpenKeys={["1"]} appearance="subtle">
      <Sidenav.Body color="white">
       <Nav defaultActiveKey="1">
        <LinkContainer to="/">
         <Nav.Item eventKey="1" icon={<Icon as={MdHome} />}>
          Home
         </Nav.Item>
        </LinkContainer>

        <Nav.Menu
         eventKey="2"
         trigger="hover"
         title="Employee"
         icon={<Icon as={MdGroup} />}
         placement="rightStart"
        >
         <LinkContainer to="/employeeList">
          <Nav.Item eventKey="2-1" icon={<Icon as={MdGroup} />}>
           {" Employee List"}
          </Nav.Item>
         </LinkContainer>
         {userInfo.isAdmin && (
          <LinkContainer to="/addEmployee">
           <Nav.Item eventKey="2-2" icon={<Icon as={MdPersonAdd} />}>
            {" Add Employee"}
           </Nav.Item>
          </LinkContainer>
         )}
        </Nav.Menu>
        <LinkContainer to="/attendance" eventKey="3" className="mb-3">
         <NavLink>
          <img
           src={iconAttendanceMachine}
           alt="attendance machine"
           style={{
            width: "20px",
            height: "20px",
            marginLeft: "18px",
           }}
           loading="lazy"
          />
          <span style={{ paddingLeft: "18px" }}></span>
          {expand ? "Attendance" : <></>}
         </NavLink>
        </LinkContainer>

        {/* <LinkContainer to="/outletList">
         <Nav.Item eventKey="4" icon={<Icon as={MdStore} />}>
          {" Outlet List"}
         </Nav.Item>
        </LinkContainer>*/}
        {userInfo.isAdmin && (
         <LinkContainer to="/userList" eventKey="4" className="mb-3">
          <NavLink>
           <img
            src={iconUsers}
            alt="users"
            style={{
             width: "20px",
             height: "20px",
             marginLeft: "18px",
            }}
            loading="lazy"
           />
           <span style={{ paddingLeft: "18px" }}></span>
           {expand ? "Users" : <></>}
          </NavLink>
         </LinkContainer>
        )}

        <LinkContainer to="/profile">
         <NavLink>
          {userInfo ? (
           <img
            src={
             userInfo && userInfo.profilePicture
              ? `../${userInfo.profilePicture}`
              : userInfo &&
                !userInfo.profilePicture &&
                `../uploads/images/profilePictures/blank-profile-picture.png`
            }
            alt="Profile"
            style={{
             width: "30px",
             height: "30px",
             borderRadius: "50%",
             marginLeft: "12px",
            }}
            loading="lazy"
           />
          ) : (
           <i
            className="fas fa-user-circle"
            style={{
             fontSize: "1.75em",
             borderRadius: "50%",
             marginLeft: "18px",
            }}
           />
          )}
          <span style={{ paddingLeft: "14px" }}></span>
          {expand && userInfo && userInfo.name ? userInfo.name : <></>}
         </NavLink>
        </LinkContainer>
        {userInfo.isAdmin && (
         <LinkContainer to="/settings">
          <Nav.Item eventKey="6" icon={<Icon as={MdSettings} />}>
           {" Settings"}
          </Nav.Item>
         </LinkContainer>
        )}

        {expand && (
         <Nav.Item
          eventKey="7"
          icon={<Icon as={MdLogout} />}
          onClick={(e) => logoutHandler(e)}
          style={{ color: "red" }}
         >
          Sign out
         </Nav.Item>
        )}
       </Nav>
      </Sidenav.Body>
     </Sidenav>
     {!expand && <Row className="mb-3"></Row>}
     <NavToggle expand={expand} onChange={expandToggleHandler} />
    </RSuiteSidebar>
   </Container>
  </div>
 );
};

const NavToggle = ({ expand, onChange }) => {
 return (
  <Stack className="nav-toggle" justifyContent={expand ? "flex-end" : "center"}>
   <IconButton
    onClick={onChange}
    appearance="subtle"
    size="lg"
    icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
   />
  </Stack>
 );
};

const Logo = ({ children, expand }) => {
 return (
  <HStack className="page-brand" spacing={12} justifyContent="center">
   <Image
    src={logoImage}
    alt="Logo"
    style={
     expand
      ? { width: "125px", height: "80px" }
      : { width: "40px", height: "25px" }
    }
   />
  </HStack>
 );
};

export default SideNavigationBar;
